@tailwind base;
@tailwind components;
@tailwind utilities;

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

html, body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', 'Helvetica Neue', sans-serif;
  font-size: 16px;
  color: #000;
  background-color: rgb(21, 95, 117);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("./assets/img/smoke2.png"), url("./assets/img/smoke3.png"), linear-gradient(45deg, rgb(21, 95, 117) 0%, rgb(21, 95, 120) 50%, rgb(21, 95, 117) 100%);
  background-size: contain, 600px;
  background-position: left bottom, top right;
  background-repeat: no-repeat;
}

bodymain {
  background-color: #fff;
  background-image: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
